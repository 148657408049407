#projects {
    padding: 4rem;
    text-align: center;

    @media(max-width: 959px) {
        padding: 4rem 2rem;
    }

    h2 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 36px;
        line-height: 3.5rem;

        &::before{
            content: '< ';

            @media(max-width: 363px) {
                content: ' ';
            }
        }

        &::after{
            content: ' />';

            @media(max-width: 363px) {
                content: ' ';
            }
        }
    }

    .content {
        display: flex;
        align-items: stretch;
        justify-content: space-evenly;
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;
        padding: 4rem 1.25rem;
        font-weight: 300;
        color: $text;
        line-height: 2rem;

        @media(max-width: 1280px) {
            display: block;
        }

        @media(max-width: 959px) {
            padding: 4rem 0;
        }

        .item {
            background: $dark_blue;
            border-radius: 5px;
            padding: 2rem;
            max-width: 350px;
            width: 100%;
            border: 1px solid transparent;
            transition: border 0.25s ease-in-out;
            text-align: left;

            @media(max-width: 1280px) {
                max-width: 1200px;
                margin: 0 auto;
            }

            a {
                text-decoration: none !important;
                color: $text !important;

                img {
                    width: 100%;
                }
            }

            &:hover {
                border: 1px solid $blue_opacity;
            }

            h4 {
                padding: 2rem 0;
                font-size: 26px;
                color: $white;
            }
        }

        .whiteSpace {
            padding: 1rem 0;
        }
    }
}