#about {
    padding: 4rem;
    text-align: center;
    background-color: #02030B;

    @media(max-width: 959px) {
        padding: 4rem 2rem;
    }

    h2 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 36px;
        line-height: 3.5rem;

        &::before{
            content: '< ';

            @media(max-width: 363px) {
                content: ' ';
            }
        }

        &::after{
            content: ' />';

            @media(max-width: 363px) {
                content: ' ';
            }
        }
    }

    article {
        text-align: center;
        
        p {
            margin: 0 auto;
            max-width: 800px;
            width: 100%;
            padding: 4rem 1.25rem;
            font-weight: 300;
            color: $text;
            line-height: 2rem;
            
            @media(max-width: 959px) {
                padding: 4rem 0;
            }

            a {
                color: $text;
                text-decoration: none;
                
                &:hover {
                    color: $blue_opacity;
                    text-decoration: none;
                }
            }
        }
    }

    .social {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        
        @media(max-width: 480px) {
            display: block;
            text-align: center;
        }
        
        a {
            color: $white;
            padding: 1rem;
            max-width: 100px;
            width: 100%;
            border-radius: 5px;
            border: 1px solid transparent;
            text-decoration: none;
            transition: border 0.25s ease-in-out;

            @media(max-width: 480px) {
                display: block;
                margin: 0 auto;
            }
            
            &:hover {
                text-decoration: none;
                color: $white;
                border: 1px solid $blue_opacity;
            }

            p {
                color: $white;
                font-size: 14px;
                padding: 1rem 0 0 !important;
                letter-spacing: 2px;
                line-height: inherit;
            }
            
            .link {
                color: $text !important;
                font-size: 12px;
                letter-spacing: 1px !important;
                padding: 0.5rem 0 0 !important;
            }

        }
    }
}