@import 'main/welcome';
@import 'main/about';
@import 'main/skills';
@import 'main/services';
@import 'main/projects';
@import 'main/contact';

main {
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    user-select: none;
}