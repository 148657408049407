footer {
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    padding: 2rem 4rem;
    user-select: none;
    background-color: #02030B;

    @media(max-width: 959px) {
        padding: 2rem;
    }
    
    article {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding-top: 2rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        border-top: 1px solid $blue_opacity;

        .footer-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $text;

            @media(max-width: 959px) {
                display: block;
                margin: 0 auto;
                text-align: center;
            }

            .powered {
                padding-bottom: 2rem;

                a {
                    color: $white;
                    cursor: pointer;
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            
        }
    }
}