#welcome {
    height: calc(100vh - 157px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 4rem;

    @media(max-width: 959px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }

    img {
        max-width: 350px;
        width: 100%;
        border-radius: 50%;

        @media(max-width: 959px) {
            max-width: 200px;
            padding-bottom: 2rem;
        }

        @media(max-width: 363px) {
            max-width: 150px;
        }
    }

    article {
        max-width: 600px;
        padding: 0 1.25rem;

        @media(max-width: 959px) {
            text-align: center;
        }
        
        h1 {
            font-size: 36px;
            line-height: 3.5rem;
        }

        p {
            color: $text;
            padding: 2rem 0;
        }

        .btns_welcome {
            display: flex;
            align-items: center;
            justify-content: flex-start;            
            max-width: 1200px;
            width: 100%;

            @media(max-width: 959px) {
                flex-direction: column; 
            }
            
            a {
                background-color: transparent;
                padding: 0.75rem 1.5rem;
                margin-right: 1rem;
                border: 1px solid $blue_opacity;
                border-radius: 5px;
                color: $text;
                text-transform: uppercase;
                text-decoration: none;
                cursor: pointer;
                transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
                max-width: 150px;
                width: 100%;
                text-align: center;

                @media(max-width: 959px) {
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
                
                &:hover {
                    text-decoration: none;
                    color: $white;
                    background-color: $blue_opacity;
                }
            }
        }
    }
}