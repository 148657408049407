#contact {
    padding: 4rem;
    text-align: center;
    background-color: #02030B;

    @media(max-width: 959px) {
        padding: 4rem 2rem;
    }

    h2 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 36px;
        line-height: 3.5rem;

        &::before{
            content: '< ';

            @media(max-width: 363px) {
                content: ' ';
            }
        }

        &::after{
            content: ' />';

            @media(max-width: 363px) {
                content: ' ';
            }
        }
    }
}