header {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 4rem;
    user-select: none;
    position: fixed;
    background: $blue;
    z-index: 2;
    
    @media(max-width: 959px) {
        padding: 2rem;
    }
    
    nav {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.25rem;
        
        h1 {
            font-weight: 300;
            color: $white;
            cursor: default;

            @media(max-width: 363px) {
                display: none;
            }
            
            &.responsive {
                display: none;
    
                @media(max-width: 363px) {
                    display: block;
                }
            }
        }


        ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 500px;
            width: 100%;

            @media(max-width: 959px) {
                display: block;
                max-width: 100%;
                width: 100%;
                padding: 15px;
                height: calc(100vh - 93px);
                position: fixed;
                overflow: hidden;
                top: 93px;
                right: 0;
                transform: translate(100%,0);
                z-index: 99999;
                background-color: $blue;
                transition: all 0.4s ease-in-out;
    
                &.active {
                    transform: translate(0,0);
                    padding: 2rem 4rem;
                }
            }

            li {
                color: $text;
                cursor: pointer;

                @media(max-width: 959px) {
                    text-align: center;
                    padding: 2rem 0;
                }

                a {
                    color: $text;
                    text-decoration: none;
                    transition: color 0.25s ease-in-out;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        .mobile {
            display: block;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            font-size: 20px;

            @media(min-width: 960px) {
                display: none;
            }

            .btn-menu-bg {
                background-color: transparent;
                border-radius: 0;
                padding: 0;
                display: block;
                
                .btn-menu {
                    display: block;
                    height: 18px;
                    position: relative;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    transition: opacity 0.5s ease-in-out;
                    cursor: pointer;

                    .hamburger {
                        display: block;
                        width: 25px;
                        height: 2px;
                        background: $white;
                        transition: all 0.5s ease-out;
                        
                        &::before, &::after {
                            display: block;
                            position: absolute;
                            content:"";
                            width: 25px;
                            height: 2px;
                            background: $white;
                            transition: all 0.5s ease-out;
                        }
                        
                        &::before {
                            top: 0px;
                        }
                        
                        &::after {
                            top: 16px;
                        }
                    }
                    
                    &.active {
                        
                        @media(max-width: 1350px) {
                            height: 22px;    
                        }
                        
                        .hamburger {
                            background: none;
                            
                            &::before, &::after {
                                top: 10px;
                            }
                            
                            &::before {
                                transform: rotate(-45deg);
                            }
                            
                            &::after {
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }
        }
    }
}