// Color Schema
$black: #181818;
$white: #F3F5F7;

$dark_blue: #02030B;
$blue: #030513;
$blue_opacity: #013C89;

$title: #313131;
$text: #B6B6B6;

// Fonts
@font-face {
    font-family: InterRegular;
    src: url('../fonts/Inter-Regular.woff2');
}
@font-face {
    font-family: InterMedium;
    src: url('../fonts/Inter-Medium.woff2');
}
@font-face {
    font-family: LexendDecaRegular;
    src: url('../fonts/LexendDeca-Regular.woff2');
}

// Global Assets
* {
    margin: 0;
    padding: 0;
    outline: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "InterRegular";
    letter-spacing: 1px;
    height: 100vh;
    color: $white;
    background: $blue;
    background-attachment: fixed;

    @media(max-width: 959px) {
        &.active {
            overflow: hidden;
        }
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        background: $blue_opacity;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $blue_opacity;
    }
}

// Styles
@import 'components/components';
@import 'components/header';
@import 'components/main';
@import 'components/footer';